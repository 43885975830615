import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthGuard, OrgPlanningAdminGuard, RoleGuard, RoleGuardData } from '@coin/modules/auth/data-management';
import { AuthGmsClientCallbackComponent } from '@coin/modules/auth/feature';
import { EnvironmentGuard } from '@coin/shared/data-management-guards';
import { NoBackendScreenComponent } from '@coin/shared/feature-legacy-components';
import { FrontendType, PermissionAction, PermissionResource } from '@coin/shared/util-enums';
import { Admin } from '@coin/shared/util-helpers';

const routes: Routes = [
  {
    path: 'auth/callback',
    component: AuthGmsClientCallbackComponent
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'logout',
    loadChildren: () => import('./log-out/log-out.module').then(m => m.LogOutModule)
  },
  {
    path: 'pdfcreate',
    loadChildren: () => import('./pdf-create/pdf-create.module').then(m => m.PdfCreateEmbeddedModule)
  },
  {
    path: Admin.segment('SeasonMgmtModule'),
    loadChildren: () => import('./season-mgmt/season-mgmt.module').then(m => m.SeasonMgmtModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.Seasons
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('TranslationsModule'),
    loadChildren: () => import('./translations/translations.module').then(m => m.TranslationsModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.Translations
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('CmsModule'),
    loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.All
        },
        {
          resource: PermissionResource.Templates
        },
        {
          resource: PermissionResource.CMS
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('MasterDataModule'),
    loadChildren: () => import('./master-data/master-data.module').then(m => m.MasterDataModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.GeneralCluster
        },
        {
          resource: PermissionResource.Mercer
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'communication',
    loadChildren: () => import('./communication/communication.module').then(m => m.CommunicationModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.Communication
        },
        {
          resource: PermissionResource.Templates,
          actions: [PermissionAction.All]
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'user-mgmt',
    loadChildren: () => import('./user-mgmt/user-mgmt.module').then(m => m.UserMgmtModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.UserManagement
        },
        {
          resource: PermissionResource.RoleManagement
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'templates-library',
    loadChildren: () => import('./templates-library/templates-library.module').then(m => m.TemplatesLibraryModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.Templates,
          actions: [PermissionAction.All]
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('IncentiveModule'),
    loadChildren: () => import('./incentive/incentive.module').then(m => m.IncentiveModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.KPIList
        },
        {
          resource: PermissionResource.KPIValues
        },
        {
          resource: PermissionResource.Factors
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'org-planning',
    loadChildren: () => import('./org-planning/org-planning.module').then(m => m.OrgPlanningModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(OrgPlanningAdminGuard).canActivate(route, state)],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.OrganisationPlanning
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('OrgManagementModule'),
    loadChildren: () => import('./org-management/org-management.module').then(m => m.OrgManagementModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      featureFlag: 'orgManagement',
      allowedResources: [
        {
          resource: PermissionResource.OrganisationManagementAdmin
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('EmploymentConditionsLibraryModule'),
    loadChildren: () => import('./employment-conditions-library/employment-conditions-library.module').then(m => m.EmploymentConditionsLibraryModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.EmploymentConditionLibraryAdmin
        },
        {
          resource: PermissionResource.EmploymentConditionLibraryRestricted
        },
        {
          resource: PermissionResource.EmploymentConditionLibraryGenerator
        },
        {
          resource: PermissionResource.SignatureAuthorizationAdmin
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('RestructuringModule'),
    loadChildren: () => import('./restructuring/restructuring.module').then(m => m.RestructuringModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      featureFlag: 'restructuring',
      allowedResources: [
        {
          resource: PermissionResource.RestructuringAdmin
        },
        {
          resource: PermissionResource.RestructuringAdminRestricted
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: Admin.segment('SuccessionManagementModule'),
    loadChildren: () => import('./succession-management/succession-management.module').then(m => m.SuccessionManagementModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.SuccessionManagementAdmin
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'interfaces',
    loadChildren: () => import('./interfaces/interfaces.module').then(m => m.InterfacesModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.All
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'api-tests',
    loadChildren: () => import('./api-test-page/api-test-page.module').then(m => m.ApiTestPageModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    canMatch: [(route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)],
    data: {
      featureFlag: 'apiTestPage',
      allowedResources: [
        {
          resource: PermissionResource.All
        }
      ] as RoleGuardData[],
      frontendType: FrontendType.admin
    }
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
  },
  {
    path: 'fallback',
    component: NoBackendScreenComponent
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
