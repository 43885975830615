<div class="container">
  <div class="dialog-headline">
    <h3>{{ getTranslationKey('headline') | translate }}</h3>
  </div>
  <div class="dialog-content">
    <div class="explanation-wrapper">
      <div class="head">
        <h5>{{ 'dialog.confirm-checklist.explanation' | translate }}</h5>
      </div>
      <div class="content">
        <p class="explanation">{{ getTranslationKey('explanation') | translate }}</p>
      </div>
    </div>
    <div class="checklist-wrapper">
      <div class="head">
        <h5>{{ 'dialog.confirm-checklist.checklist' | translate }}</h5>
      </div>
      <div class="content">
        <ul class="checklist">
          @for (item of checklist; track item) {
            <li>
              <p>{{ item }}</p>
            </li>
          }
          @if (!checklist.length) {
            <div>
              <div class="no-items-warning">
                <mat-icon>warning</mat-icon>
                <span>No checklist items. Please add the first translation:</span>
              </div>
              <p>{{ getTranslationKey('checklist-item-1') }}</p>
            </div>
          }
        </ul>
      </div>
    </div>
  </div>
  <coin-v2-dialog-footer color="white" padding="large">
    <coin-v2-checkbox [formControl]="checkListControl" [label]="'dialog.confirm-checklist.accept-checklist' | translate" />
    <div class="v2-footer-spacer"></div>
    <coin-action-button (click)="cancel()" svgIcon="cancel">
      {{ 'general.btnClose' | translate }}
    </coin-action-button>
    <coin-action-button [disabled]="checkListControl.value === false" (click)="accept()" svgIcon="forward">
      {{ 'general.accept' | translate }}
    </coin-action-button>
  </coin-v2-dialog-footer>
</div>
